import templateStr from 'text!./pm-edit-vehicle.html';
import moment from 'moment';

// Edge doesn't support Map<Integer, String>, and since we might want to control the labels on the frontend anyway,
// this maps available values in PC to labels.
const NCD_YEARS_OPTIONS_LABEL = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10+'
};

export default [() => {
    return {
        restrict: 'A',
        scope: {
            vehicle: '=',
            driver: '=',
            vehicleImageUrl: '=?',
            readonly: '=',
            ncdYearsOptions: '<',
            afterMarketOptions: '=',
            bodyworkModifications: '=',
            engineModifications: '=',
            wheelsOrTyresModifications: '=',
            conversionModifications: '=',
            otherModifications: '=',
            updateVehicle: '='
        },
        template: templateStr,
        controller: ['$scope', '$rootScope', 'brandingData', ($scope, $rootScope, brandingData) => {

            $scope.minDate = moment([$scope.vehicle.firstRegisteredYear.value]);
            $scope.maxDate = moment();

            $scope.$watch('vehicle.firstRegisteredYear.value', () => {
                $scope.minDate = moment([$scope.vehicle.firstRegisteredYear.value]);
            });

            $scope.annualMileageTooltip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.How many miles do you think youll drive over the next 12 months?',
                trigger: 'click'
            };

            $scope.keepAtTooltipDisplayKey = 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.Will you* be the owner and registered keeper of the car?';
            $scope.classOfUseTooltipDisplayKey = 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.How do you use your car?';

            if($rootScope.productCode === 'CommercialVehicle') {
                $scope.keepAtTooltipDisplayKey = 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.Will you* be the owner and registered keeper of the car?.kin';
                $scope.classOfUseTooltipDisplayKey = 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.How do you use your car?.kin';
            }

            $scope.keepAtTooltip = {
                content: $scope.keepAtTooltipDisplayKey,
                trigger: 'click'
            };
            $scope.classOfUseTooltip = {
                content: $scope.classOfUseTooltipDisplayKey,
                trigger: 'click'
            };

            $scope.$watch('vehicle.firstRegisteredYear.value', () => {
                $scope.minDate = moment([$scope.vehicle.firstRegisteredYear.value]);
            });
            
            $scope.annualMileageTooltip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.How many miles do you think youll drive over the next 12 months?',
                trigger: 'click'
            };
            $scope.keepAtTooltip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.Will you* be the owner and registered keeper of the car?',
                trigger: 'click'
            };
            $scope.classOfUseTooltip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.How do you use your car?',
                trigger: 'click'
            };

            //Filter Typelist to no longer include 'Not available' Using the NonMigrations Filter. 
            const dayTimeParkingTypelist = 'NonMigratingPoliciesDayTimeParking'; 
            const dayTimeParkingFilters = $scope.vehicle.dayTimeParking.aspects.availableValues[0].typelist.filters; 
            $scope.DayTimeParkingPrefixes = dayTimeParkingFilters.find(obj => {
                return obj.name === dayTimeParkingTypelist; 
            }); 


            // NCD
            $scope.ncdYearValues = $scope.ncdYearsOptions // Build typelist-like array
                .map((code) => {
                    return {
                        code,
                        name: NCD_YEARS_OPTIONS_LABEL[code]
                    };
                });

            let driverAge = (DriverDOB) => {
                let DOB = new Date(DriverDOB.year, DriverDOB.month, DriverDOB.day);
                let today = new Date();
                let age = ((today - DOB) / (31557600000));
                age = Math.floor( age );
                return age;
            };
            $scope.driverAge = driverAge($scope.driver.dateOfBirth.value);

            let yearsSincePassedTest = (driver) => {
                if(driver.testPassed10Years.value === true) {
                    return 10;
                }
                else {
                    if (driver.passedTestMonth.value == null || driver.passedTestYear.value == null) {
                        return null;
                    } else {
                        let passedTestDayOfMonth = 1;
                        let passedTestMonth = driver.passedTestMonth.value.priority / 10;
                        let passedTestYear = driver.passedTestYear.value;

                        let passedTest = new Date(passedTestYear, passedTestMonth, passedTestDayOfMonth);
                        let today = new Date();
                        let years = ((today - passedTest) / (31557600000));
                        years = Math.floor(years);
                        return years;
                    }
                }
            };
            $scope.yearsSincePassedTest = yearsSincePassedTest($scope.driver);

            // Driving Other Cars Experience
            let DrivingExperinceOptions = (driver, vehicle, yearsSincePassedTest, driverAge) => {
                let array = [];
                if(!driver.value.hasFullLicence()) {
                    array.push(vehicle.drivingExperienceYears.aspects.availableValues[0]);
                }
                else {
                    let maxDrivingExperiencePossible = driverAge - 17;
                    let maxDrivingExperience = 0;
                    if(yearsSincePassedTest == null) {
                        maxDrivingExperience = maxDrivingExperiencePossible;
                    }
                    else if(yearsSincePassedTest <= maxDrivingExperiencePossible) {
                        maxDrivingExperience = yearsSincePassedTest;
                    }
                    else {
                        maxDrivingExperience = maxDrivingExperiencePossible;
                    }

                    if(maxDrivingExperience >= 4) {
                        array = vehicle.drivingExperienceYears.aspects.availableValues;
                    }
                    else {
                            vehicle.drivingExperienceYears.aspects.availableValues.forEach(drivingExp => {
                                let drivingExperienceYearsNumber = parseInt(drivingExp.code);
                                if(maxDrivingExperience >= drivingExperienceYearsNumber) {
                                    array.push(drivingExp);
                                }
                            });
                    }
                }
                return array;
            };

            let ncdYearsAvailableValues = (driver, vehicle, yearsSincePassedTest, driverAge, ncdYears) => {
                let array = [];
                const maxDrivingExperiencePossible = driverAge - 17;
                let maxDrivingExperience = 0;
                if (yearsSincePassedTest == null) {
                    maxDrivingExperience = maxDrivingExperiencePossible;
                } else if (yearsSincePassedTest <= maxDrivingExperiencePossible) {
                    maxDrivingExperience = yearsSincePassedTest;
                } else {
                    maxDrivingExperience = maxDrivingExperiencePossible;
                }

                if (maxDrivingExperience >= 10) {
                    array = ncdYears;
                } else {
                    ncdYears.forEach(ncdYear => {
                        if (maxDrivingExperience >= ncdYear.code) {
                            array.push(ncdYear);
                        }
                    });
                }
                return array;
            };
            $scope.ncdYearsAvailableValues = ncdYearsAvailableValues($scope.driver, $scope.vehicle, $scope.yearsSincePassedTest, $scope.driverAge, $scope.ncdYearValues);

            $scope.ncdYearsDrivingOthercars = {
                value: $scope.vehicle.drivingExperienceYears.value,
                aspects: DrivingExperinceOptions($scope.driver, $scope.vehicle, $scope.yearsSincePassedTest, $scope.driverAge)
            };

            $scope.$watch(() => $scope.ncdYearsDrivingOthercars.value, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    $scope.vehicle.drivingExperienceYears.value = newValue;
                }
            });

            if(brandingData === 'kfi') {
                $scope.vehicle.haveYouBeenDrivingOtherCars.value = false;
            }

            $scope.$watch('vehicle.registeredKeeper.value', (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if (brandingData !== 'kfi' && $scope.vehicle.registeredKeeper.value === false) {
                        $scope.vehicle.vehicleIsLeased.value = false;
                    }
                }
            });

            $scope.$watch(() => $scope.vehicle.abiCode.value, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if(newValue == undefined) {
                        $scope.vehicle.eligibleForUsageBasedInsurance = null;
                        $scope.vehicle.eligibleForFullHybridInsurance = null;
                        $scope.vehicle.eligibleForPlugInHybridInsurance = null;
                    }
                    else {
                        $scope.vehicle.quickSave = true;
                        $scope.updateVehicle();
                    }
                }
            });
            
            $scope.$watch(() => $scope.vehicle.marketValue.amount.value, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if (newValue == null || newValue === 0) {
                        $scope.vehicle.marketValue.amount.value = undefined; 
                    } else {
                        $scope.vehicle.marketValue.amount.value = newValue;
                    }
                }
            });
        }]
    };
}];